import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
    Stack,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useGetUanToEmploymentHistoryMutation } from '../features/api/deepApiSlice';
import { modalStyles as style } from '../utils/cssStyles';

const UanToEmploymentHistory = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [getUanToEmploymentHistory, { data, isLoading, isFetching, isError, isSuccess, error }] =
        useGetUanToEmploymentHistoryMutation();
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        onSubmit: async (values) => {
            await getUanToEmploymentHistory({
                uan_number: values.uan_number,
            }).unwrap();
            setModalOpen(true);
        },
    });

    const employmentHistory = data?.data?.employment_history || [];

    const handleNext = () => {
        if (currentPage < employmentHistory.length - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const currentRecord = employmentHistory[currentPage];

    const download_uan_history = (report_link) => {
        const link = document.createElement('a');
        link.href = report_link;
        // Generating a unique name with a 4-digit hash
        const hash = Math.floor(1000 + Math.random() * 9000);
        const uniqueName = `credit_report_${hash}`;
        link.download = uniqueName;
        link.click();
        link.remove();
    };


    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <Box sx={{ ...style }}>
                    <Box
                        mb={1}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        Uan to EmploymentHistory &nbsp;{' '}
                        <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                    </Box>
                    <Grid container mb={3}>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Name
                            </Grid>
                            <Grid item>{currentRecord?.name || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Guardian Name
                            </Grid>
                            <Grid item>{currentRecord?.guardian_name || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Establishment Name
                            </Grid>
                            <Grid item>{currentRecord?.establishment_name || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Member ID
                            </Grid>
                            <Grid item>{currentRecord?.member_id || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Date of Joining
                            </Grid>
                            <Grid item>{currentRecord?.date_of_joining || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Date of Exit
                            </Grid>
                            <Grid item>{currentRecord?.date_of_exit || '-'}</Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Button
                            variant="contained"
                            onClick={handlePrevious}
                            disabled={currentPage === 0}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Previous
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleNext}
                            disabled={currentPage === employmentHistory.length - 1}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Next
                        </Button>
                    </Grid>
                    <Grid container justifyContent="center" mt={2} p={2} spacing={2}>
                        {isSuccess && data?.data?.pdf_url && (
                            <Grid item>
                                <Button
                                    variant="contained"
                                    onClick={() => download_uan_history(data?.data?.pdf_url)}
                                    color="secondary"
                                    sx={{
                                        width: 120,
                                        padding: 1,
                                        color: 'white',
                                        backgroundColor: 'green',
                                        '&:hover': {
                                            backgroundColor: 'darkgreen',
                                        },
                                    }}
                                >
                                    Download
                                </Button>
                            </Grid>
                        )}
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setModalOpen(false);
                                }}
                                color="secondary"
                                sx={{
                                    width: 120,
                                    padding: 1
                                }}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Verification Suite
                </Typography>
            </Grid>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="Uan to Employment History"
                        subheader="The API to fetch the Employment History of the user linked with the UAN number."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Uan Number</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="Uan Number"
                                    size="small"
                                    type="text"
                                    name="uan_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {isError && (
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {error?.data?.message ||
                                                error?.data?.detail ||
                                                'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default UanToEmploymentHistory;
